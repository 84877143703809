import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Button from '@mui/material/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateTdmStepper from '../components/CreateTdmStepper';
import { getTdmFileById, selectTransitRowSliceData, setTdmJsonDefault } from '../../store/TransitSlice';
import AddIcon from '@mui/icons-material/Add';

function TdmDetailPageHeader() {
    const [showDialog, setShowDialog] = useState(false);
    const [fileData, setFileData] = useState({});
    const userData = useSelector(selectUser)
    const rowData = useSelector(selectTransitRowSliceData)
    const dispatch = useDispatch()


    const navigate = useNavigate()
    const params = useParams()

    const handleGoBack = () => {
        navigate('/apps/transitDataManager/demoTDM')
    };

    const handleClickOpen = (row) => {
        setShowDialog(true);
    };

    useEffect(() => {
        if (!rowData || Object.keys(rowData)?.length <= 0) {
            let body = {
                id: params?.id,
            }
            dispatch(getTdmFileById(body)).then((res) => {
                if (res?.payload?.response && res?.payload?.response.status != 200) {
                    handleGoBack()
                } else {
                    setFileData(res?.payload?.data || {})
                }
            }).catch((err) => {
                handleGoBack()
            })
        } else {
            setFileData(rowData || {})
        }
    }, [])

    return (
        <>
            <div className="p-24 sm:p-32 w-full flex flex-col sm:flex-row space-y-8 sm:space-y-0 items-center justify-between">
                <div className="flex flex-col items-center sm:items-start space-y-8 sm:space-y-0">
                    <motion.span
                        className="flex items-end"
                        initial={{ x: -20 }}
                        animate={{ x: 0, transition: { delay: 0.2 } }}
                        delay={300}
                    >
                        <div className="flex flex-row items-center space-x-16">
                            <IconButton onClick={handleGoBack} aria-label="go back">
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography
                                // component={Link}
                                // to="/apps/transitDataManager/demoTDM"
                                className="text-20 md:text-32 font-extrabold tracking-tight leading-none"
                                // role="button"
                            >
                                {fileData?.agency_key}
                            </Typography>
                        </div>
                    </motion.span>
                </div>

                <div className="flex items-center -mx-8">
                    <Button
                        className="mx-8 whitespace-nowrap"
                        variant="contained"
                        color="secondary"
                        onClick={handleClickOpen}
                    >
                        <AddIcon />
                        Json
                    </Button>
                </div>
            </div>
            {showDialog && <CreateTdmStepper showDialog={showDialog} setShowDialog={setShowDialog} rowData={fileData} dialogType="create" />}
        </>

    );
}

export default TdmDetailPageHeader;
