import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Invalid access_token');
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          resolve(response.data.user);
          this.emit('onLogin', response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/signin`, {
          email,
          password,
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token);
            this.setAdminSession(response.data.access_token);
            this.emit('onLogin', response.data.user);
            resolve(response.data.user);
          } else {
            reject(response.data.error);
          }
        });
    });
  };

  signInWithToken = async () => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/signInWithToken`)
        .then((response) => {
          if (response.data.user) {
            resolve(response.data.user);
            this.setSession(response.data.access_token);
          } else {
            this.logout();
            reject(new Error('Failed to login with token.'));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token, imp) => {
    if (access_token) {
      localStorage.setItem('jwt_user_access_token', access_token);
      if (imp != undefined) {
        localStorage.setItem('jwt_user_imp', imp);
      }
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_user_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  setAdminSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_admin_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_admin_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    this.setAdminSession(null);
    window.localStorage.removeItem('jwt_provider_access_token');
    window.localStorage.removeItem('provider-impersonate');
    window.localStorage.removeItem('jwt_user_imp');
    window.localStorage.clear();
    this.emit('onLogout', 'Logged out');
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  checkImpersonate = () => {
    return window.localStorage.getItem('jwt_user_imp');
  }

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_user_access_token');
  };

  getAdminAccessToken = () => {
    return window.localStorage.getItem('jwt_admin_access_token');
  };
}

const instance = new JwtService();

export default instance;