import React, { useState, useEffect } from 'react';
import {
    Box, Stepper, Step, StepLabel, StepContent, Button, Paper, Typography, Dialog, DialogContent, TextField,
    Autocomplete, Container, LinearProgress, StepIcon,
    IconButton,
    DialogActions,
    DialogTitle,
    Snackbar,
    Alert,
    Chip,
    Stack
} from '@mui/material';
import ReactJson from 'react-json-view';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRoutes, getCurrentTrips, getRouteInfo, getStopsByTripId, saveTdmJson, selectStopsByTripData, selectTransitTripSliceData, setStopsDataByTripId, updateTdmJson } from '../../store/TransitSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import { selectUser } from 'app/store/userSlice';
import StopSelector from './StopSelector';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useParams } from 'react-router-dom';

const steps = [
    { label: 'Select RouteID', description: 'You need to select a route Id to continue' },
    { label: 'Select TripID, Direction, and Operator', description: 'You can Go back if you want to Change Route Id' },
    { label: 'Finalize Your Action', description: 'You can Go back if you want to edit the previous step' },
    { label: 'View Json', description: 'Click to view TDM Demo Data' }
];

export default function CreateTdmStepper({ showDialog, setShowDialog, rowData, dialogType, myJson }) {
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openJsonViewer, setOpenJsonViewer] = useState(false);
    const [jsonData, setJsonData] = useState({});
    const [routeIds, setRouteIds] = useState([]);
    const [tripIds, setTripIds] = useState([]);
    const [selectedRouteId, setSelectedRouteId] = useState(null);
    const [selectedTripId, setSelectedTripId] = useState(null);
    const [selectedTripDirection, setSelectedTripDirection] = useState(null);
    const [tripDirection, setTripDirection] = useState([]);
    const [fileData, setFileData] = useState(null);
    const [operatorName, setOperatorName] = useState("");
    const [fileName, setFileName] = useState(null);
    const [snackMesaage, setSnackMesaage] = useState(null);
    const [snackVarient, setSnackVarient] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [saveTdmBody, setSaveTdmBody] = useState(null);
    const [inBoundData, setInBoundData] = useState([]);
    const [outBoundData, setOutBoundData] = useState([]);
    const [stopsData, setStopsData] = useState([]);
    const [selectedstopsId, setSelectedStopsId] = useState([]);
    const [unFilteredStops, setUnFilteredStops] = useState([]);
    const [loadedStops, setLoadedStops] = useState([]);
    const [stopNames, setStopNames] = useState([]);

    const [routeIdToProcess, setRouteIdToProcess] = useState(null)

    const [routeLongName, setRouteLongName] = useState("")

    const dispatch = useDispatch();
    const tripsData = useSelector(selectTransitTripSliceData);
    const userData = useSelector(selectUser)
    const { id } = useParams();

    const handleNext = () => setActiveStep(prev => prev + 1);
    const handleBack = (index) => {
        if (index === 1) {
            setSelectedTripDirection(null)
            // setSelectedRouteId(null)
            setSelectedTripId(null)
            setOperatorName("")
        }
        setActiveStep(prev => prev - 1)
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const getTimestampName = () => {
        let timestamp = (new Date(fileData?.createdAt).getTime() / 1000).toString();
        timestamp = timestamp.replace(/\.\d+$/, '');
        setFileName(`${timestamp}_${fileData?.hexa_code}`)
    };

    const downloadJsonFile = () => {
        let agency_key = rowData?.agency_key
        const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json' });
        saveAs(blob, fileName);
    };


    const handleConfirm = async (index) => {
        setLoading(true);
        let body = {
            agency_key: rowData?.agency_key || null,
            hexacode: rowData?.hexa_code || rowData?.hexacode || null,
            // routeId: selectedRouteId?.match(/^\d+/)?.[0] || null,
            routeId: routeIdToProcess || selectedRouteId?.match(/^\d+/)?.[0] || null,
            tripId: selectedTripId,
            directionId: selectedTripDirection === "Inbound" ? 1 : 0,
            operator: operatorName,
            type: rowData?.type,
            stopIds: selectedstopsId,
            routeLongName: routeLongName,
            stopNames: stopNames
        };

        // if(selectedRouteId?.includes("-")){
        //     body = {
        //         ...body,
        //         routeId:
        //     }
        // }

        try {
            let res, proceedNext = false;
            if (index === 0) {
                res = await dispatch(getCurrentTrips(body));
                if (res?.payload?.response?.status && res?.payload?.response?.status != 200) {
                    setTimeout(() => {
                        if (res?.payload?.response?.status === 404) {
                            setSnackVarient("warning")
                            setSnackMesaage("Trips Not Found For This Route")
                            setLoading(false);
                            setSnackOpen(true)
                        } else {
                            setSnackVarient("error")
                            setSnackMesaage("Something Went wrong Please try again")
                            setLoading(false);
                            setSnackOpen(true)
                        }
                    }, 2000)
                } else {
                    const tripData = res?.payload?.tripInfo?.map(trip => trip?.trip_id).filter(Boolean) || [];
                    const outBound = res?.payload?.tripInfo?.map(trip => {
                        if (trip?.direction_id === "0") {
                            return trip?.trip_id
                        } else {
                            return null;
                        }
                    }).filter((e) => e != null) || [];

                    const inBound = res?.payload?.tripInfo?.map(trip => {
                        if (trip?.direction_id === "1") {
                            return trip?.trip_id
                        } else {
                            return null;
                        }
                    }).filter((e) => e != null) || [];

                    setInBoundData(inBound)
                    setOutBoundData(outBound)

                    setTimeout(() => {
                        setLoading(false);
                        handleNext()
                    }, 1000)
                }
            } else if (index === 1) {
                res = await dispatch(getRouteInfo(body));
                if (res?.payload?.response?.status && res?.payload?.response?.status != 200) {
                    setTimeout(() => {
                        if (res?.payload?.response?.status === 404) {
                            setSnackVarient("warning")
                            setSnackMesaage("Stops Not Found For This Trip")
                            setLoading(false);
                            setSnackOpen(true)
                        } else {
                            setSnackVarient("error")
                            setSnackMesaage("Something Went wrong Please try again")
                            setLoading(false);
                            setSnackOpen(true)
                        }
                    }, 2000)
                } else {
                    let json = res?.payload;
                    let body = {
                        tdmfile_id: rowData?.id || id,
                        json,
                        created_by: userData?.id,
                        hexa_code: rowData?.hexa_code || rowData?.tdm_hexa_code || null,
                    }
                    setSaveTdmBody(body)
                    setJsonData(json);
                    proceedNext = true
                    setLoading(false);
                    handleNext()
                }
            } else {
                if (dialogType === "edit") {
                    dispatch(updateTdmJson(saveTdmBody)).then((res) => {
                        setFileData(res?.payload?.recentData)
                    })
                } else {
                    dispatch(saveTdmJson(saveTdmBody)).then((res) => {
                        setFileData(res?.payload?.recentData)
                    })
                }
                setTimeout(() => {
                    setLoading(false);
                    handleNext()
                }, 1500)
            }
        } catch {
            setTimeout(() => {
                setSnackVarient("error")
                setSnackMesaage("Something Went wrong Please try again")
                setLoading(false);
                setSnackOpen(true)
            }, 2000)
        }
    };

    const handleViewJson = () => setOpenJsonViewer(true);

    useEffect(() => {
        const fetchData = async () => {
            const body = { agency_key: rowData?.agency_key || null, hexacode: rowData?.hexa_code || rowData?.hexacode || null, type: rowData?.type };
            try {
                const res = await dispatch(getAllRoutes(body));
                if (res?.payload?.response?.status && res?.payload?.response?.status != 200) {
                    setTimeout(() => {
                        if (res?.payload?.response?.status === 404) {
                            setSnackVarient("warning")
                            setSnackMesaage("Routes Not Found For This Data Base")
                            setSnackOpen(true)
                        } else if (res?.payload?.response?.status === 502) {
                            setSnackVarient("error")
                            setSnackMesaage("Data Base Doest Not Exists or Unable to Establish Connection")
                            setSnackOpen(true)
                        } else {
                            setSnackVarient("error")
                            setSnackMesaage("Something Went wrong Please try again")
                            setSnackOpen(true)
                        }
                    }, 2000)
                } else {
                    const data = res?.payload?.data?.map(route => `${route?.route_id || ""} ${route?.route_long_name || ""}`) || [];
                    setRouteIds(data);
                }
            } catch {
                setTimeout(() => {
                    setSnackVarient("error")
                    setSnackMesaage("Something Went wrong Please try again")
                    setSnackOpen(true)
                }, 2000)
            }
        };
        fetchData();

    }, [dialogType]);

    useEffect(() => {
        if (dialogType === "edit") {
            let routeIdNew;
            if (rowData?.routeId) {
                const [routeId, ...routeNameParts] = rowData?.routeId?.split(" ");
                routeIdNew = routeId
                setRouteIdToProcess(routeId)
            } else {
                setRouteIdToProcess(null)
            }

            const body = {
                agency_key: rowData?.agency_key || null,
                hexacode: rowData?.hexacode || null,
                routeId: routeIdNew || rowData?.routeId || null,
                tripId: rowData?.tripId || null,
                directionId: rowData?.directionId ?? null,
                operator: rowData?.operator || "",
                type: rowData?.type
            };

            dispatch(getCurrentTrips(body)).then((res) => {
                if (res?.payload?.response?.status && res?.payload?.response?.status != 200) {
                    if (res?.payload?.response?.status === 404) {
                        setSnackVarient("warning")
                        setSnackMesaage("Trips Not Found For This Route")
                        setLoading(false);
                        setSnackOpen(true)
                    } else {
                        setSnackVarient("error")
                        setSnackMesaage("Something Went wrong Please try again")
                        setLoading(false);
                        setSnackOpen(true)
                    }
                } else {
                    const tripData = res?.payload?.tripInfo?.map(trip => trip?.trip_id).filter(Boolean) || [];
                    const outBound = res?.payload?.tripInfo?.map(trip => {
                        if (trip?.direction_id === "0") {
                            return trip?.trip_id
                        } else {
                            return null;
                        }
                    }).filter((e) => e != null) || [];

                    const inBound = res?.payload?.tripInfo?.map(trip => {
                        if (trip?.direction_id === "1") {
                            return trip?.trip_id
                        } else {
                            return null;
                        }
                    }).filter((e) => e != null) || [];

                    setInBoundData(inBound)
                    setOutBoundData(outBound)

                    setTripIds(rowData?.directionId === "0" ? outBound : inBound)
                }
            });

            dispatch(getStopsByTripId(body)).then((res) => {
                if (res?.payload?.response && res?.payload?.response?.statusCode != 200) {
                    setSnackVarient("warning")
                    setSnackMesaage("Stops Not Found for this Trip Id")
                    setSnackOpen(true)
                } else {
                    setUnFilteredStops(res?.payload?.data)
                }
            })

            const routeName = `${rowData?.routeId} ${myJson?.[0]?.trip?.route_long_name}`
            setActiveStep(1)
            setSelectedRouteId(routeName || "")
            setSelectedTripId(rowData?.tripId || null)
            setSelectedTripDirection(rowData?.directionId === "1" ? "Inbound" : "Outbound")
            setOperatorName(rowData?.operator || "")
            setRouteLongName(myJson?.[0]?.trip?.route_long_name || rowData?.routeLongName || "")

            setSelectedStopsId(rowData?.stopIds || [])
            setStopNames(rowData?.stopNames || [])
        }
    }, [dialogType === "edit"])

    useEffect(() => {
        getTimestampName()
    }, [fileData])

    const handleRouteChange = (event, newValue) => {
        if (newValue) {
            setSelectedRouteId(newValue)
            const [routeId, ...routeNameParts] = newValue?.split(" ");
            const routeName = routeNameParts?.join(" ");
            setRouteIdToProcess(routeId)
            setRouteLongName(routeName)
        } else {
            setRouteIdToProcess(null)
            setRouteLongName("")
        }

    };

    const handleTripChange = (event, newValue) => {
        setStopNames([])
        setSelectedStopsId([])
        setUnFilteredStops([])
        setSelectedTripId(newValue);
        const direction = tripsData?.find(trip => trip?.trip_id === newValue)?.direction_id || "0";
        setTripDirection(prev => [...new Set([...prev, direction])]);

        const body = {
            agency_key: rowData?.agency_key || null,
            hexacode: rowData?.hexa_code || rowData?.hexacode || null,
            // routeId: selectedRouteId?.match(/^\d+/)?.[0] || rowData?.routeId || null,
            routeId: routeIdToProcess || selectedRouteId?.match(/^\d+/)?.[0] || rowData?.routeId || null,
            tripId: newValue,
            directionId: selectedTripDirection === "Inbound" ? 1 : selectedTripDirection === "Outbound" ? 0 : rowData?.directionId ?? null,
            operator: operatorName || rowData?.operator || "",
            type: rowData?.type
        };
        dispatch(getStopsByTripId(body)).then((res) => {
            if (res?.payload?.response && res?.payload?.response?.statusCode != 200) {
                setSnackVarient("warning")
                setSnackMesaage("Stops Not Found for this Trip Id")
                setSnackOpen(true)
            } else {
                setUnFilteredStops(res?.payload?.data)
            }
        })
    };

    // useEffect(() => {
    //     const body = {
    //         agency_key: rowData?.agency_key || null,
    //         hexacode: rowData?.hexa_code || rowData?.hexacode || null,
    //         routeId: selectedRouteId?.match(/^\d+/)?.[0] || rowData?.routeId || null,
    //         tripId: selectedTripId || rowData?.tripId,
    //         directionId: rowData?.directionId || selectedTripDirection === "Inbound" ? 1 : 0,
    //         operator: operatorName || rowData?.operator,
    //         type: rowData?.type
    //     };
    //     dispatch(getStopsByTripId(body))
    // }, [selectedTripId && (!unFilteredStops)])

    const handleDirectionChange = (event, newValue) => {
        setStopNames([])
        setSelectedStopsId([])
        setUnFilteredStops([])
        setSelectedTripId(null)
        setSelectedTripDirection(newValue)
        if (newValue === "Inbound") {
            setTripIds(inBoundData)
        } else {
            setTripIds(outBoundData)
        }
    }

    const handleOperatorChange = (event) => setOperatorName(event.target.value);

    return (
        <Dialog open={showDialog} onClose={() => setShowDialog(false)} fullWidth maxWidth="lg" style={{ maxHeight: "90vh !important", maxWidth: "60vw !important", padding: 0 }}>
            {openJsonViewer ? (
                <DialogContent className='p-20'>
                    <div className="flex justify-between w-full items-center">
                        <DialogTitle id="alert-dialog-title">{fileName}</DialogTitle>

                        <IconButton
                            size="large"
                            onClick={() => setShowDialog(false)}
                        >
                            <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
                        </IconButton>
                    </div>
                    <DialogContent>
                        <ReactJson
                            name="json"
                            enableClipboard={true}
                            displayDataTypes={false}
                            collapsed={false}
                            theme="monokai"
                            src={jsonData}
                            style={{ padding: "1rem", maxHeight: "50vh", overflow: "auto" }}
                        />
                    </DialogContent>
                    <DialogActions className='gap-10'>
                        <Button onClick={() => setShowDialog(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={downloadJsonFile} color="primary" variant="contained" startIcon={<DownloadIcon />}>
                            Download
                        </Button>
                    </DialogActions>
                </DialogContent>

            ) : (
                <DialogContent className='p-56'>
                    <div className="flex justify-between w-full items-center">
                        <DialogTitle id="alert-dialog-title">
                            {dialogType === "edit" ? "Procceed To Edit Demo TDM" : "Procceed To create Demo TDM"}
                        </DialogTitle>

                        <IconButton
                            size="large"
                            onClick={() => setShowDialog(false)}
                        >
                            <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
                        </IconButton>
                    </div>
                    <Stepper activeStep={activeStep} orientation="vertical" sx={{ padding: "2rem" }}>
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel StepIconComponent={(props) => <StepIcon {...props} style={{ color: index < activeStep && 'green' || (index === 3 && activeStep === index) && 'green' }} />}>
                                    {step.label}
                                </StepLabel>
                                <StepContent>
                                    <div className='flex justify-between items-center w-full'>
                                        <Typography>{step.description}</Typography>
                                        {index === 1 &&
                                            (
                                                <StopSelector setSelectedStopsId={setSelectedStopsId} selectedstopsId={selectedstopsId} selectedTripId={selectedTripId} selectedTripDirection={selectedTripDirection} unFilteredStops={unFilteredStops} setStopNames={setStopNames} stopNames={stopNames} />
                                            )
                                        }
                                    </div>
                                    {loading ? (
                                        <LinearProgress color='secondary' sx={{ height: "0.5rem", marginTop: "1rem" }} />
                                    ) : (
                                        <Box className='flex flex-col justify-center gap-24 w-full h-full'>
                                            {index === 0 && (
                                                <Autocomplete
                                                    autoFocus
                                                    options={routeIds}
                                                    value={selectedRouteId}
                                                    onChange={handleRouteChange}
                                                    renderInput={(params) => <TextField {...params} margin="dense" label="Select RouteId" fullWidth variant="outlined" />}
                                                />
                                            )}
                                            {index === 1 && (
                                                <>
                                                    <Autocomplete
                                                        options={["Outbound", "Inbound"]}
                                                        value={selectedTripDirection}
                                                        onChange={handleDirectionChange}
                                                        renderInput={(params) => <TextField {...params} margin="dense" label="Select Direction" fullWidth variant="outlined" />}
                                                    />
                                                    <Autocomplete
                                                        autoFocus
                                                        options={tripIds}
                                                        value={selectedTripId}
                                                        onChange={handleTripChange}
                                                        renderInput={(params) => <TextField {...params} margin="dense" label="Select TripId" fullWidth variant="outlined" onClick={() => {
                                                            if (!selectedTripDirection) {
                                                                setSnackVarient("warning")
                                                                setSnackMesaage("Please Select Direaction")
                                                                setSnackOpen(true)
                                                            }
                                                        }} />}
                                                        disabled={selectedTripDirection ? false : true}
                                                    />
                                                    <TextField
                                                        margin="dense"
                                                        label="Operator"
                                                        fullWidth
                                                        variant="outlined"
                                                        value={operatorName}
                                                        onChange={handleOperatorChange}
                                                    />
                                                    <Stack direction="row" spacing={2} sx={{ padding: "1rem", overflow: "auto", width: "100%" }}>
                                                        {Array.isArray(stopNames) && stopNames.length > 0 ? (
                                                            stopNames.map((stop, index) => (
                                                                <Chip
                                                                    label={stop}
                                                                    variant="outlined"
                                                                    key={index}
                                                                    color="primary"
                                                                />
                                                            ))
                                                        ) : (
                                                            <Typography>No Custom Stops Selected</Typography>
                                                        )}
                                                    </Stack>

                                                </>
                                            )}
                                            <Container>
                                                {
                                                    index === 0 && (
                                                        <Button
                                                            className="mx-8 whitespace-nowrap"
                                                            variant="contained"
                                                            color="secondary"
                                                            size='large'
                                                            onClick={() => handleConfirm(index)}
                                                            disabled={index === 0 && selectedRouteId && true || index != 0 && selectedTripId && selectedTripDirection ? false : true}
                                                        >
                                                            Confirm
                                                        </Button>
                                                    )
                                                }
                                                {index > 0 && index !== 3 && (
                                                    <>
                                                        <Button
                                                            className="mx-8 whitespace-nowrap"
                                                            variant="contained"
                                                            color="secondary"
                                                            size='large'
                                                            onClick={() => handleConfirm(index)}
                                                            disabled={index === 0 && selectedRouteId && true || index != 0 && selectedTripId && selectedTripDirection && unFilteredStops?.length != 0 ? false : true}
                                                        >
                                                            Confirm
                                                        </Button>
                                                        <Button
                                                            className="mx-8 whitespace-nowrap"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleBack(index)}
                                                            size='large'
                                                        >
                                                            Go Back
                                                        </Button>
                                                    </>
                                                )}
                                                {index === 3 && (
                                                    <Button
                                                        className="mx-8 whitespace-nowrap"
                                                        variant="contained"
                                                        color="secondary"
                                                        size='large'
                                                        onClick={handleViewJson}
                                                        disabled={!routeIds.length}
                                                    >
                                                        Demo Json
                                                    </Button>
                                                )}
                                            </Container>
                                        </Box>
                                    )}
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </DialogContent>
            )}
            <Snackbar
                open={snackOpen}
                autoHideDuration={2000}
                onClose={handleCloseSnack}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleCloseSnack} variant="filled" severity={snackVarient} sx={{ width: '100%' }}>
                    {snackMesaage}
                </Alert>
            </Snackbar>
        </Dialog >
    );
}
