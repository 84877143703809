import Button from "@mui/material/Button";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import FuseLoading from "@fuse/core/FuseLoading";
import _ from "@lodash";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Box from "@mui/system/Box";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { selectUser } from "app/store/userSlice";

import {
  addAgency,
  getAgency,
  newAgency,
  removeAgency,
  selectAgency,
  updateAgency,
} from "../store/agencySlice";

import PhoneNumberSelector from "./phone-number-selector/PhoneNumberSelector";
import { getAgencies } from "../store/agenciesSlice";
import SharedDiag from "../../../../shared-components/SharedDiag";
import ToastAlert from "app/shared-components/ToastAlert";
import React from "react";
import { useState } from "react";
import { getAllProvider } from "../store/agencySlice";
import { toast } from "react-toastify";
import { setOperationStatus } from "../store/agenciesSlice";
import { showMessage } from "app/store/fuse/messageSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import ConfirmDialog from "app/shared-components/ConfirmDialog";

const languageOptions = [
  {
    id: "en",
    label: "English",
  },
  {
    id: "ge",
    label: "Germany",
  },
  {
    id: "ch",
    label: "Chinese",
  },
];

const customerRoleOptions = [
  {
    id: "self-managed",
    label: "Self-Managed",
  },
  {
    id: "co-managed",
    label: "Co-Managed",
  },
  {
    id: "fully-managed",
    label: "Fully-Managed",
  },
];

const permissionOptions = [
  {
    id: "monitoring",
    name: "Monitoring",
  },
  {
    id: "daas",
    name: "DAAS",
  },
  {
    id: "logging",
    name: "Logging",
  },
  {
    id: "ads",
    name: "Dashboard - Ad+",
  },
  {
    id: "playerstats",
    name: "Dashboard - Players Stats",
  },
  {
    id: "apkmanagement",
    name: "APK Management",
  },
  {
    id: "gtfs",
    name: "GTFS/GTFS-RT",
  },
  {
    id: "player",
    name: "Player Group",
  },
  {
    id: "displays",
    name: "Display",
  },
  {
    id: "alert-trigger",
    name: "Alert Trigger",
  },
  {
    id: "cradlePoint",
    name: "Cradle Point",
  },
  {
    id: "ipConfigurations",
    name: "Ip Configurations",
  },
  {
    id: "ibus",
    name: "ibus",
  }
];

const AvailUrl = {
  stopUrl: "/InfoPoint/rest/StopDepartures/Get/",
  routeUrl: "/InfoPoint/rest/Routes/GetAllRoutes",
  vehicleUrl: "/InfoPoint/rest/Vehicles/GetAllVehicles",
  publicUrl: "/InfoPoint/rest/PublicMessages/GetAllMessages",
};

const AgencyForm = (props) => {
  const agency = useSelector(selectAgency);
  const routeParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [permissionGtfs, setPermissionGtfs] = useState(false);
  const [permissionDaas, setPermissionDaas] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [agencyKey, setAgencyKey] = useState(null);

  const schema = yup.object().shape({
    vehicle_url:
      permissionGtfs && yup.string().required("You must enter a vehicle url."),
    trip_url:
      permissionGtfs && yup.string().required("You must enter a trip url."),
    alert_url:
      permissionGtfs && yup.string().required("You must enter a alert url."),
    datafeed:
      permissionGtfs &&
      yup.string().required("You must enter a data feed url."),
  });

  const { control, watch, reset, handleSubmit, formState, setValue, trigger } =
    useForm({
      mode: "onChange",
      // resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;
  const [DialogStatus, setDialogStatus] = useState(false);
  const [providers, setProviders] = useState([]);
  const notify = toast();
  const [playerType, setPlayerType] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [origUrl, setOrigUrl] = useState("");

  const form = watch();

  useEffect(() => {
    dispatch(getAllProvider()).then((providerData) => {
      setProviders(providerData?.payload);
      if (user?.role == "provider-admin") {
        setPlayerType(user?.providerName?.label);
        setValue("provider_id", user?.providerName);
      }
    });
    reset({ ...agency });
  }, [agency, reset]);

  useEffect(() => {
    if (playerType == "Avail" && routeParams?.id === "new") {
      setValue("stop_api", AvailUrl?.stopUrl);
      setValue("route_api", AvailUrl?.routeUrl);
      setValue("vehicle_api", AvailUrl?.vehicleUrl);
      setValue("public_api", AvailUrl?.publicUrl);
    } else if (routeParams?.id === "new") {
      setValue("stop_api", "");
      setValue("route_api", "");
      setValue("vehicle_api", "");
      setValue("public_api", "");
    }
  }, [playerType]);

  useEffect(() => {
    if (routeParams.id === "new") {
      reset(
        {
          language: ["en"],
          permission: [],
          provider_id: "",
          ibus_api_key: "",
          swagger_url: "",
        },
        {
          keepDefaultValues: true,
        }
      );

      dispatch(newAgency());
    } else {
      dispatch(getAgency(routeParams?.id)).then(({ payload }) => {
        setPlayerType(payload?.provider?.name);
        setValue("username", payload.users.username);
        setValue("email", payload.users.email);
        setBaseUrl(payload?.provider_details[0]?.baseUrl);
        setOrigUrl(payload?.provider_details[0]?.baseUrl);
        setValue("stop_api", payload.provider_details[0].stop_api);
        setValue("route_api", payload.provider_details[0].route_api);
        setValue("vehicle_api", payload.provider_details[0].vehicle_api);
        setValue("public_api", payload.provider_details[0].public_api);
        if (payload?.agency_information) {
          setValue("vehicle_url", payload?.agency_information?.vehiclePosition);
          setValue("trip_url", payload?.agency_information?.tripUpdate);
          setValue("alert_url", payload?.agency_information?.alert);
          setValue("datafeed", payload?.agency_information?.datafeed);
        }
        setValue("language", JSON.parse(payload?.language));
        setValue("permission", JSON.parse(payload?.permission));
        setValue("provider_id", {
          value: payload.provider_id,
          label: payload.provider.name,
        });
        if (JSON.parse(payload?.permission).some((v) => v == "gtfs")) {
          setPermissionGtfs(true);
        } else {
          setPermissionGtfs(false);
        }
        if (JSON.parse(payload?.permission).some((v) => v == "daas")) {
          setPermissionDaas(true);
        } else {
          setPermissionDaas(false);
        }
      });
    }
  }, [dispatch, routeParams, setValue, trigger]);

  /**
   * Form Submit
   */
  function onSubmit(data) {
    setAgencyKey(data?.username);
    dispatch(setOperationStatus(""));
    if (routeParams.id === "new") {
      if (playerType == "Avail") {
        data.stop_api = baseUrl + data?.stop_api;
        data.route_api = baseUrl + data?.route_api;
        data.vehicle_api = baseUrl + data?.vehicle_api;
        data.public_api = baseUrl + data?.public_api;
      }
      data["provider_id"] = user?.providerName
        ? user?.providerName?.value
        : data?.provider_id?.value;
      data["baseUrl"] = baseUrl;
      dispatch(addAgency(data)).then((addAgencyResponse) => {
        if (addAgencyResponse?.payload?.staff?.ResponseCode == 200) {
          dispatch(getAgencies());
          if (data?.permission?.includes("gtfs")) {
            // navigate(`/apps/gtfs/agency/${data?.username}/home`)
            setOpenDialog(true);
          } else {
            navigate(`/apps/agencies`);
          }
          dispatch(setOperationStatus(addAgencyResponse?.payload?.message));

          setTimeout(() => {
            dispatch(setOperationStatus(""));
          }, 3000);
        } else {
          dispatch(setOperationStatus("Something wrong..."));
          setTimeout(() => {
            dispatch(setOperationStatus(""));
          }, 2000);
        }
      });
    } else {
      var newData = {};
      newData["agency_id"] = data?.agency_id;
      newData["user_id"] = data?.user_id;
      newData["username"] = data?.username;
      newData["firstname"] = data?.firstname;
      newData["lastname"] = data?.lastname;
      newData["role"] = 3;
      newData["customer_role"] = data?.customer_role;
      newData["provider_id"] = user?.providerName
        ? user?.providerName?.value
        : data?.provider_id?.value;
      newData["language"] = data?.language;
      newData["permission"] = data?.permission;
      newData["password"] = data?.password;
      newData["api_key"] = data?.api_key;
      newData["ibus_api_key"] = data?.ibus_api_key;
      newData["swagger_url"] = data?.swagger_url;
      newData["contacts"] = data?.contacts;
      newData["provider_detail_id"] =
        data?.provider_details[0]?.provider_detail_id;
      newData["baseUrl"] = baseUrl;
      newData["stop_api"] = data?.stop_api;
      newData["route_api"] = data?.route_api;
      newData["vehicle_api"] = data?.vehicle_api;
      newData["public_api"] = data?.public_api;
      newData["vehicle_url"] = permissionGtfs ? data?.vehicle_url : "";
      newData["trip_url"] = permissionGtfs ? data?.trip_url : "";
      newData["alert_url"] = permissionGtfs ? data?.alert_url : "";
      newData.contacts.forEach((datas) => {
        delete datas?.createdAt;
        delete datas?.updatedAt;
        delete datas?.agency_id;
        delete datas?.contact_id;
      });
      newData["datafeed"] = permissionGtfs ? data?.datafeed : "";
      newData["allow_daas_config"] = data?.allow_daas_config ? true : false;
      dispatch(updateAgency(newData)).then(() => {
        navigate(`/apps/agencies`);
        dispatch(getAgencies());
        dispatch(setOperationStatus("Agency has been updated..."));
        setTimeout(() => {
          dispatch(setOperationStatus(""));
        }, 2000);
      });
    }
  }

  function handleRemoveAgency(StatusAgree) {
    if (StatusAgree) {
      let id = {
        id: agency.agency_id,
      };
      dispatch(removeAgency(id)).then(() => {
        navigate("/apps/agencies");
        dispatch(getAgencies());
      });
    } else {
      navigate("/apps/agencies");
      dispatch(getAgencies());
    }
  }

  const handlePlayerType = (e) => {
    setPlayerType(e?.label);
  };

  const handleBaseURL = (e) => {
    setBaseUrl(e.target.value);
    setValue("stop_api", e.target.value + AvailUrl?.stopUrl);
    setValue("route_api", e.target.value + AvailUrl?.routeUrl);
    setValue("vehicle_api", e.target.value + AvailUrl?.vehicleUrl);
    setValue("public_api", e.target.value + AvailUrl?.publicUrl);
  };

  const handleNaviagteToGtfs = () => {
    if (agencyKey) {
      navigate(`/apps/gtfs/agency/${agencyKey}/home`);
    }
  };

  const handleNaviagteToAgency = () => {
    navigate(`/apps/agencies`);
  };

  if (_.isEmpty(form) || !agency) {
    return <FuseLoading />;
  }

  return (
    <>
      {DialogStatus && (
        <SharedDiag
          status={DialogStatus}
          OnhandleRemove={handleRemoveAgency}
          message={"Confirm delete the agency..."}
        />
      )}
      {/* <ToastAlert /> */}
      <Box
        className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.default",
        }}
      ></Box>

      <div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
        <Divider textAlign="left" className="w-full mt-32">
          <Chip label="Agency Information for Admin Account Creation" />
        </Divider>
        {user?.role != "provider-admin" && (
          <Controller
            name="provider_id"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value, onBlur, ref } }) => (
              <Autocomplete
                className="mt-32"
                freeSolo
                options={providers && providers}
                value={value}
                fullWidth
                onChange={(event, newValue) => {
                  handlePlayerType(newValue);
                  onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select provider"
                    label="Provider"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={!!errors.Autocomplete}
                    helperText={errors?.Autocomplete?.message}
                    onBlur={onBlur}
                    inputRef={ref}
                  />
                )}
              />
            )}
          />
        )}
        <Controller
          name="customer_role"
          control={control}
          defaultValue={[]}
          render={({ field: { onChange, value, onBlur, ref } }) => (
            <Autocomplete
              className="mt-32"
              freeSolo
              options={customerRoleOptions}
              value={value}
              fullWidth
              onChange={(event, newValue) => {
                onChange(newValue?.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Customer Role"
                  label="Customer Role"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.Autocomplete}
                  helperText={errors?.Autocomplete?.message}
                  onBlur={onBlur}
                  inputRef={ref}
                />
              )}
            />
          )}
        />
        <div className="flex gap-48  w-full">
          <div className="w-full">
            <Controller
              control={control}
              name="firstname"
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="First Name"
                  placeholder="First Name Agency"
                  id="firstname"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-solid:user-circle
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              control={control}
              name="lastname"
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Last Name"
                  placeholder="Last Name Agency"
                  id="lastname"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-solid:user-circle
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="flex gap-48 w-full">
          <div className="w-full">
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Agency Key"
                  placeholder="Agency Key"
                  id="username"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-solid:user-circle
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          {routeParams.id === "new" ? (
            <div className="w-full">
              <Controller
                control={control}
                name="language"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple
                    id="language"
                    className="mt-32"
                    placeholder="Select multiple tags"
                    label="Tags"
                    variant="outlined"
                    options={languageOptions}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.label}
                    renderOption={(_props, option, { selected }) => (
                      <li {..._props}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.label}
                      </li>
                    )}
                    onChange={(event, newValue) => {
                      onChange(newValue?.map((item) => item?.id));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Language"
                        placeholder="Languages"
                      />
                    )}
                  />
                )}
              />
            </div>
          ) : (
            <div className="w-full">
              <Controller
                control={control}
                name="language"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple
                    id="language"
                    className="mt-32"
                    options={languageOptions}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.label}
                    renderOption={(_props, option, { selected }) => (
                      <li {..._props}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.label}
                      </li>
                    )}
                    value={
                      value
                        ? value.map((id) => _.find(languageOptions, { id }))
                        : []
                    }
                    onChange={(event, newValue) => {
                      onChange(newValue?.map((item) => item?.id));
                    }}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Language"
                        placeholder="Languages"
                      />
                    )}
                  />
                )}
              />
            </div>
          )}
        </div>

        <div className="flex gap-48 w-full">
          <div className="w-full">
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Email"
                  placeholder="Agency Email"
                  id="email"
                  disabled={routeParams.id !== "new" ? true : false}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-solid:user-circle
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Password"
                  placeholder="Agency Password"
                  id="password"
                  type="password"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-solid:user-circle
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>

        <Divider textAlign="left" className="w-full mt-32">
          <Chip label="Agency API Key for Player Fetch" />
        </Divider>

        <Controller
          control={control}
          name="api_key"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="Onsign Api Key"
              placeholder="Onsign Api Key"
              id="apikey"
              error={!!errors.company}
              helperText={errors?.company?.message}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:office-building
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="ibus_api_key"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="IBus Api Key"
              placeholder="IBus Api Key"
              id="ibusapikey"
              error={!!errors.company}
              helperText={errors?.company?.message}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:office-building
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Divider textAlign="left" className="w-full mt-32">
          <Chip label="Support Contact Details" />
        </Divider>

        <Controller
          control={control}
          name="contacts"
          render={({ field }) => (
            <PhoneNumberSelector className="mt-32" {...field} />
          )}
        />

        {playerType == "Avail" && (
          <TextField
            className="mt-32"
            label="Base Url"
            placeholder="Base Url"
            id="baseurl"
            error={!!errors.company}
            helperText={errors?.company?.message}
            value={baseUrl}
            onChange={handleBaseURL}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FuseSvgIcon size={20}>
                    heroicons-solid:office-building
                  </FuseSvgIcon>
                </InputAdornment>
              ),
            }}
          />
        )}

        <Divider textAlign="left" className="w-full mt-32">
          <Chip label="Agecny API's Feed" />
        </Divider>

        <Controller
          control={control}
          name="stop_api"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="Stop API"
              placeholder="Stop API"
              id="stopapi"
              error={!!errors.company}
              helperText={errors?.company?.message}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:office-building
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        {playerType == "Avail" && (
          <>
            <Controller
              control={control}
              name="route_api"
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Route API"
                  placeholder="Route API"
                  id="routeapi"
                  error={!!errors.company}
                  helperText={errors?.company?.message}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-solid:office-building
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="vehicle_api"
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Vehicle API"
                  placeholder="Vehicle API"
                  id="vehicleapi"
                  error={!!errors.company}
                  helperText={errors?.company?.message}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-solid:office-building
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="public_api"
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Public Api"
                  placeholder="Public Api"
                  id="publicid"
                  error={!!errors.company}
                  helperText={errors?.company?.message}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-solid:office-building
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </>
        )}

        <Controller
          control={control}
          name="swagger_url"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="Swagger Url"
              placeholder="Swagger Url"
              id="swaggerurl"
              // error={!!errors.company}
              // helperText={errors?.company?.message}
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:office-building
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="permission"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              multiple
              id="permission"
              className="mt-32"
              options={permissionOptions}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(_props, option, { selected }) => (
                <li {..._props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.name}
                </li>
              )}
              value={
                value
                  ? value.map((id) => _.find(permissionOptions, { id }))
                  : []
              }
              onChange={(event, newValue) => {
                if (newValue.some((v) => v?.id == "gtfs")) {
                  setPermissionGtfs(true);
                } else {
                  setPermissionGtfs(false);
                }
                if (newValue.some((v) => v?.id == "daas")) {
                  setPermissionDaas(true);
                  // setValue("allow_daas_config", false);
                } else {
                  setPermissionDaas(false);
                  setValue("allow_daas_config", false);
                }
                onChange(newValue.map((item) => item.id));
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Permission"
                  placeholder="Permissions Allow"
                />
              )}
            />
          )}
        />
        {permissionDaas ? (
          <Controller
            control={control}
            name="allow_daas_config"
            render={({ field }) => (
              <FormControlLabel
                className="mr-auto mt-16"
                control={<Checkbox {...field} checked={field?.value} />}
                label="Allow Daas Authentication"
              />
            )}
          />
        ) : (
          ""
        )}
        {/* {permissionGtfs && (
          <>
            <Divider textAlign="left" className="w-full mt-32">
              <Chip label="GTFS/GTFS-RT Urls Feed" />
            </Divider>

            <Controller
              control={control}
              name="vehicle_url"
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Vehicle Url"
                  placeholder="Vehicle Url"
                  id="vehicleurl"
                  error={!!errors.vehicle_url}
                  helperText={errors?.vehicle_url?.message}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-solid:office-building
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="trip_url"
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Trip Url"
                  placeholder="Trip Url"
                  id="tripurl"
                  error={!!errors.trip_url}
                  helperText={errors?.trip_url?.message}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-solid:office-building
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="alert_url"
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Alert Url"
                  placeholder="Alert Url"
                  id="alerturl"
                  error={!!errors.alert_url}
                  helperText={errors?.alert_url?.message}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-solid:office-building
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="datafeed"
              render={({ field }) => (
                <TextField
                  className="mt-32"
                  {...field}
                  label="Gtfs Static Url"
                  placeholder="Gtfs Static Url"
                  id="datafeed"
                  error={!!errors.datafeed}
                  helperText={errors?.datafeed?.message}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FuseSvgIcon size={20}>
                          heroicons-solid:office-building
                        </FuseSvgIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </>
        )} */}
      </div>

      <Box
        className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t"
        sx={{ backgroundColor: "background.default" }}
      >
        {routeParams.id !== "new" && (
          <Button
            color="error"
            onClick={() => {
              setDialogStatus(!DialogStatus);
            }}
          >
            Delete
          </Button>
        )}
        <Button className="ml-auto" component={NavLinkAdapter} to={-1}>
          Cancel
        </Button>
        {routeParams.id === "new" ? (
          <Button
            className="ml-8"
            variant="contained"
            color="secondary"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        ) : (
          <Button
            className="ml-8"
            variant="contained"
            color="secondary"
            // disabled={_.isEmpty(dirtyFields) || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>
        )}
      </Box>

      <ConfirmDialog
        open={openDialog}
        handleConfirm={handleNaviagteToGtfs}
        isDisabledConfirm={false}
        description={
          "Click Confirm To Complete Agency Information & Handle Deployments."
        }
        btnName={"Setup"}
        setOpen={setOpenDialog}
        oldFieldsValue={null}
        newFieldsValue={null}
        dialogWidth={"md"}
        onCloseFunction={() => handleNaviagteToAgency()}
        onCloseEnabled={true}
      />
    </>
  );
};

export default AgencyForm;
