import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllTdmJsons, selectTdmJsonsData, selectTransitRowSliceData, setTdmJsonDefault } from '../../store/TransitSlice';
import { ContextMenuTrigger } from 'react-contextmenu';
import { Alert, Box, IconButton, Snackbar, Typography } from '@mui/material';
import { lighten } from "@mui/material/styles";
import FuseLoading from '@fuse/core/FuseLoading';
import DeleteIcon from "@mui/icons-material/Delete";
import JsonFileItem from '../components/JsonFileItem';
import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function DemoTdmRowPageContent() {
    const [menuOpen, setMenuOpen] = useState(true);
    const [contextMenu, setContextMenu] = useState(null);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [snackMesaage, setSnackMesaage] = useState(null);
    const [snackVarient, setSnackVarient] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);

    const tdmFiles = useSelector(selectTdmJsonsData);
    const rowData = useSelector(selectTransitRowSliceData);
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleContextMenu = (event) => {
        if (menuOpen) {
            event.preventDefault();
            setContextMenu(contextMenu === null ? { mouseX: event.clientX + 2, mouseY: event.clientY - 6 } : null);
        }
    };

    useEffect(() => {
        const body = { tdmfile_id: rowData?.id || params?.id };
        dispatch(getAllTdmJsons(body)).then((res) => {
            setData(res?.payload?.data || []);
            setLoading(false);
        });
    }, [dispatch, params?.id]);

    const stopsLengths = useMemo(() => {
        let count = 0;
        if (tdmFiles) {
            tdmFiles.forEach(item => {
                if (item.json && item.json.data && item.json.data[0] && item.json.data[0].trip && item.json.data[0].trip.stops) {
                    count += item.json.data[0].trip.stops.length;
                }
            });
        }
        return count;
    }, [tdmFiles]);

    useEffect(() => {
        setData(tdmFiles)
    }, [stopsLengths]);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-full">
                <FuseLoading />
            </div>
        );
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }} className=' xl:h-[84vh] lg:h-[75vh] md:h-[75vh] sm:h-[85vh] h-[68vh] flex justify-center w-full'>
            {data.length <= 0 ?
                (
                    <div className="flex flex-wrap  m-8 justify-center items-center h-full">
                        <Typography className="font-medium text-2xl">
                            There is no Json
                        </Typography>
                    </div>
                ) : (
                    <div className="p-24 sm:p-32 border-b-1 w-full" onContextMenu={handleContextMenu} style={{ cursor: "context-menu", width: "100%" }}>
                        <Box
                            className="p-16 rounded-16 mb-24 border xl:max-h-[80vh] lg:max-h-[70vh] max-h-[65vh] overflow-auto w-full"
                            sx={{
                                backgroundColor: (theme) =>
                                    theme.palette.mode === "light"
                                        ? lighten(theme.palette.background.default, 0.4)
                                        : lighten(theme.palette.background.default, 0.02),
                            }}
                        >
                            {data.length > 0 && (
                                <div className="flex flex-col w-full justify-center">
                                    <Typography className="font-medium mb-20">TDM Jsons</Typography>
                                    <div className="flex flex-wrap -m-8 my-8 overflow-x-auto md:overflow-x-none">
                                        {data.map((item, index) => (
                                            <JsonFileItem key={index} item={item} className="w-full" />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Box>
                    </div>
                )
            }
            <Snackbar
                open={snackOpen}
                autoHideDuration={2000}
                onClose={handleCloseSnack}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleCloseSnack} variant="filled" severity={snackVarient}>
                    {snackMesaage}
                </Alert>
            </Snackbar>
        </Paper>
    );
}
