import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const Cronjob = lazy(() => import('./cronjob/CronJobs'));
const Cronjobs = lazy(() => import('./cronjobs/CronjobsList'));
const InformationTab = lazy(() => import('./cronjob/tabs/InformationTab'));
const DeployTab = lazy(() => import('./cronjob/tabs/DeployTab'));
const StatsTab = lazy(() => import('./cronjob/tabs/StatsTab'));
const HomeTab = lazy(() => import('./cronjob/tabs/HomeTab'));
const Pm2Tab = lazy(() => import('./cronjob/tabs/Pm2Tab'));
const DemoTdmTab = lazy(() => import('./cronjob/tabs/DemoTdmTab'));
const SettingsTab = lazy(() => import('./cronjob/tabs/SettingsTab'));
const SwaggerTab = lazy(() => import('./cronjob/tabs/SwaggerTab'));
const DeployAgencyTab = lazy(() => import('./cronjob/tabs/BasicInfoTab'));
const DeploymentTab = lazy(() => import('./cronjob/tabs/DeploymentTab'));

const CronjobsAppConfig = {
    settings: {
        layout: {},
    },
    auth: authRoles.admin,
    routes: [
        {
            path: 'apps/gtfs/agency',
            element: <Cronjobs />,
        },
        {
            path: 'apps/gtfs/agency/:agency_key',
            element: <Cronjob />,
            children: [
                {
                    path: 'Home',
                    element: <HomeTab />,
                },
                {
                    path: 'deploy-app',
                    element: <DeployAgencyTab />,
                },
                {
                    path: 'deployments',
                    element: <DeploymentTab />,
                },
                {
                    path: 'demo-tdm',
                    element: <DemoTdmTab />,
                },
                {
                    path: 'statistics',
                    element: <StatsTab />,
                },
                {
                    path: 'settings',
                    element: <SettingsTab />,
                },
                {
                    path: 'swagger',
                    element: <SwaggerTab />,
                },
                {
                    path: '*',
                    element: <Navigate to="home" replace />,
                },
            ],
        },
    ],
};

export default CronjobsAppConfig;
