import * as React from "react";
import Button from "@mui/material/Button";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Box from "@mui/system/Box";
import format from "date-fns/format";
import _ from "@lodash";
import { getContact, selectContact } from "../store/providerSlice";
import axios from "axios";
import JwtService from "src/app/auth/services/jwtService";

const ProvidersView = () => {
  const providerData = useSelector(selectContact);
  const routeParams = useParams();
  const dispatch = useDispatch();
  var providerDatas = {};
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getContact(routeParams.id)).then((res) => {
      setLoading(false);
    });
  }, [dispatch, routeParams]);

  if (!providerData) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <FuseLoading />
      </div>
    );
  }

  if (loading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <FuseLoading />
      </div>
    );
  }

  if (providerData) {
    providerDatas = providerData[0];
  }

  const handleImpersonateProvider = async (val) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/auth/impersonate`,
      { email: val }
    );
    if (response.data) {
      window.localStorage.setItem(
        "jwt_provider_access_token",
        response?.data?.access_token
      );
      JwtService.setSession(response?.data?.access_token, true);
      window.location.href = "/dashboards/provider";
    }
  };

  return (
    <>
      <Box
        className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.default",
        }}
      ></Box>
      <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
        <div className="w-full max-w-3xl">
          <div className="flex flex-auto items-end -mt-64">
            <div className="flex items-center ml-auto mb-4">
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  handleImpersonateProvider(providerDatas?.user?.email)
                }
              >
                <FuseSvgIcon size={20}>heroicons-outline:eye</FuseSvgIcon>
                <span className="mx-8">Impersonate</span>
              </Button>
            </div>
            <div className="flex items-center ml-10 mb-4">
              <Button
                variant="contained"
                color="secondary"
                component={NavLinkAdapter}
                to="edit"
              >
                <FuseSvgIcon size={20}>
                  heroicons-outline:pencil-alt
                </FuseSvgIcon>
                <span className="mx-8">Edit</span>
              </Button>
            </div>
          </div>
          {providerDatas ? (
            <>
              <Typography className="mt-32 text-4xl font-bold truncate">
                {providerDatas?.name}
              </Typography>
              <Divider className="mt-16 mb-24" />
              <div className="flex flex-col space-y-32">
                <div className="flex">
                  <FuseSvgIcon>heroicons-outline:user-group</FuseSvgIcon>
                  <div className="min-w-0 ml-24 space-y-4">
                    <div className="flex items-center leading-6">
                      {providerDatas?.user?.username}
                      <>
                        <Typography
                          className="text-md truncate"
                          color="text.secondary"
                        >
                          <span className="mx-8">&bull;</span>
                          <span className="font-medium">User Name</span>
                        </Typography>
                      </>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <FuseSvgIcon>heroicons-outline:user-group</FuseSvgIcon>
                  <div className="min-w-0 ml-24 space-y-4">
                    <div className="flex items-center leading-6">
                      {providerDatas?.user?.firstname}
                      <>
                        <Typography
                          className="text-md truncate"
                          color="text.secondary"
                        >
                          <span className="mx-8">&bull;</span>
                          <span className="font-medium">First Name</span>
                        </Typography>
                      </>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <FuseSvgIcon>heroicons-outline:user-group</FuseSvgIcon>
                  <div className="min-w-0 ml-24 space-y-4">
                    <div className="flex items-center leading-6">
                      {providerDatas?.user?.lastname}
                      <>
                        <Typography
                          className="text-md truncate"
                          color="text.secondary"
                        >
                          <span className="mx-8">&bull;</span>
                          <span className="font-medium">Last Name</span>
                        </Typography>
                      </>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <FuseSvgIcon>heroicons-outline:user</FuseSvgIcon>
                  <div className="min-w-0 ml-24 space-y-4">
                    <div className="flex items-center leading-6">
                      {providerDatas?.user?.email}
                      <>
                        <Typography
                          className="text-md truncate"
                          color="text.secondary"
                        >
                          <span className="mx-8">&bull;</span>
                          <span className="font-medium">Email</span>
                        </Typography>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default ProvidersView;
