import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const Bay = lazy(() => import('./bay/Bay'));
const Bays = lazy(() => import('./bays/Bays'));

const BayAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/bays/:id',
      element: <Bays />,
    },
    {
      path: 'apps/bays/:stopId/:bayId/*',
      element: <Bay />,
    },
  ],
};

export default BayAppConfig;
