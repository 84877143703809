import { lazy } from 'react';
import { authRoles } from 'src/app/auth';
const AgencyAnalyticsApp = lazy(() => import('./AgencyAnalytics/AgencyAnalyticsApp'));
const AgencyAnalyticsPlayerApp = lazy(() => import('./tabs/playerDetails/AgencyAnalyticsPlayerApp'));

const ProjectDashboardApp = lazy(() => import('./ProjectDashboardApp'));

const ProjectDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.provider,
  routes: [
    {
      path: 'dashboards/provider',
      element: <ProjectDashboardApp />,
    },
    {
      path: 'dashboards/provider/agency/:id',
      element: <AgencyAnalyticsApp />,
    },
    {
      path: 'dashboards/provider/agency/:id/player/:playerId',
      element: <AgencyAnalyticsPlayerApp />,
    },
  ],
};

export default ProjectDashboardAppConfig;
