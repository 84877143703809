import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const ProjectDashboardApp = lazy(() => import('./ProjectDashboardApp'));
const AgencyAnalyticsPlayerApp = lazy(() => import('../../dashboards-provider/project/tabs/playerDetails/AgencyAnalyticsPlayerApp'));

const ProjectDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'dashboards/agency',
      element: <ProjectDashboardApp />,
    },
    {
      path: 'dashboards/agency/player/:playerId',
      element: <AgencyAnalyticsPlayerApp />,
    },
  ],
};

export default ProjectDashboardAppConfig;
