import AcademyAppConfig from './academy/AcademyAppConfig';
import CalendarAppConfig from './calendar/CalendarAppConfig';
import ChatAppConfig from './chat/ChatAppConfig';
import ContactsAppConfig from './contacts/ContactsAppConfig';
import ECommerceAppConfig from './e-commerce/ECommerceAppConfig';
import MailboxAppConfig from './mailbox/MailboxAppConfig';
import FileManagerAppConfig from './file-manager/FileManagerAppConfig';
import ApkManagerAppConfig from './apk-manager/ApkFileManagerAppConfig';
import NotesAppConfig from './notes/NotesAppConfig';
import ScrumboardAppConfig from './scrumboard/ScrumboardAppConfig';
import TasksAppConfig from './tasks/TasksAppConfig';
import HelpCenterAppConfig from './help-center/HelpCenterAppConfig';
import ProfileAppConfig from './profile/profileAppConfig';
import ApkKeysAppConfig from './apk-keys-management/ApkKeysAppConfig';
import ProvidersAppConfig from './provider/ProvidersAppConfig';
import AgenciesAppConfig from './agencies/AgenciesAppConfig';
import StopAppConfig from './stops/StopAppConfig';
import BayAppConfig from './bays/BayAppConfig'
import MsgAppConfig from './available-msg/MsgAppConfig'
import AdAppConfig from './ads-plus/AdsAppConfig'
import logsConfig from './logs/logsConfig';
import ApiAppConfig from './api/ApiAppConfig';
import GtfsAppConfig from './gtfs/GtfsAppConfig';
import PlayerGroupAppConfig from './player-groups/PlayerGroupAppConfig';
import PlayersAppConfig from './players/PlayersAppConfig';
import DisplayAppConfig from './display/DisplaysAppConfig';
import SwaggerGtfsAppConfig from './swagger-gtfs/SwaggerGtfsAppConfig';
import CronjobsAppConfig from './agency-configs/CronjobsAppConfig';
import AlertTriggerAppConfig from './alert-trigger/AlertTriggerAppConfig';
import JsonApkFileManagerAppConfig from './Jsonapk-manager/JsonApkFileManagerAppConfig';
import AssignApkAppConfig from './assign-apk/AssignApkAppConfig';
import UserProfileConfig from './user-profile/userProfileAppConfig';
import ApkMngrAppConfig from './display/apkMngr/ApkMngrAppConfig';
import TransitDataManagerAppConfig from './transit-data-manager/TransitDataManagerAppConfig';
import CradlePointAppConfig from './cradlePoint/CradlePointNavConfig';
import IpAppConfig from './ip-configurations/IpNavConfig';

const appsConfigs = [
  // IpAppConfig,
  // CradlePointAppConfig,
  TransitDataManagerAppConfig,
  UserProfileConfig,
  MailboxAppConfig,
  FileManagerAppConfig,
  ContactsAppConfig,
  CalendarAppConfig,
  ChatAppConfig,
  ECommerceAppConfig,
  ScrumboardAppConfig,
  AcademyAppConfig,
  NotesAppConfig,
  TasksAppConfig,
  HelpCenterAppConfig,
  ProfileAppConfig,
  ApkKeysAppConfig,
  ProvidersAppConfig,
  AgenciesAppConfig,
  StopAppConfig,
  BayAppConfig,
  MsgAppConfig,
  AdAppConfig,
  logsConfig,
  ApiAppConfig,
  ApkManagerAppConfig,
  GtfsAppConfig,
  PlayerGroupAppConfig,
  PlayersAppConfig,
  DisplayAppConfig,
  SwaggerGtfsAppConfig,
  CronjobsAppConfig,
  AlertTriggerAppConfig,
  JsonApkFileManagerAppConfig,
  AssignApkAppConfig,
  ApkMngrAppConfig
];

export default appsConfigs;
