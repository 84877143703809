import { lazy } from 'react';
import { authRoles } from 'src/app/auth';


const PostedAdsApp = lazy(() => import('./tabs/ads/PostedAdListApp'));
const PostedAddViewApp = lazy(() => import('./tabs/ad/PostedAdApp'));

const AdAppConfig = {
  settings: {
    layout: {},
  }, 
  auth: authRoles.commonuser,
  routes: [
    {
      path: 'apps/ads',
      element: <PostedAdsApp />,
    },
    {
      path: 'apps/ad/view/:adId/*',
      element: <PostedAddViewApp />,
    },
  ],
};

export default AdAppConfig;
