import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Box, Container, Grid, IconButton, LinearProgress, MenuItem, TextField, Tooltip } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CircularProgress from '@mui/material/CircularProgress';
import SnackBarComponent from './SnackBarComponent';

const ConfirmDialog = ({
    open,
    handleConfirm,
    isDisabledConfirm,
    description,
    btnName,
    setOpen,
    oldFieldsValue,
    newFieldsValue,
    loaderProgress,
    loadingProgress,
    dialogWidth,
    progressStatus,
    askAppControlPasswordKey,
    setAskAppControlPasswordKey,
    permissionToken,
    setPermissionToken,
    setIsDisabledConfirm,
    onCloseEnabled,
    onCloseFunction
}) => {

    const [newDialogSize, setDialogSize] = useState(dialogWidth || "md")
    const [dialogHeight, setDialogHeight] = useState("45rem")

    const [snackOpen, setSnackOpen] = useState(false)
    const [snackMessage, setSnackMessage] = useState("")
    const [snackVarient, setSnackVarient] = useState("")
    const [agree, setAgree] = useState(null)

    const handleClose = () => {
        setOpen(false)
        if (permissionToken && permissionToken != "") {
            setPermissionToken("")
        }
        if (onCloseEnabled) {
            onCloseFunction()
        }

        if (askAppControlPasswordKey) {
            setAskAppControlPasswordKey(null)
        }
    }
    const [changedFieldJsx, setChangedFieldJsx] = useState(null);

    const changedFields = (oldValues, newValues) => {
        const changes = [];

        for (const key in newValues) {
            if (oldValues.hasOwnProperty(key)) {
                const oldValue = oldValues[key];
                const newValue = newValues[key];

                if (oldValue !== newValue) {
                    changes.push({
                        field: key,
                        oldValue: oldValue,
                        newValue: newValue
                    });
                }
            }
        }

        if (changes?.length === 1) {
            setDialogSize("md")
            setDialogHeight("")
        }
        return (
            <Grid container spacing={2} className='py-10 flex flex-col justify-center items-center h-full w-full'>
                {
                    changes?.length > 0 ? (
                        changes.map(({ field, oldValue, newValue }) => {
                            return (
                                <Container className='flex gap-10'>
                                    <TextField
                                        fullWidth
                                        required
                                        margin="normal"
                                        label={field}
                                        name={field}
                                        type='text'
                                        value={oldValue}
                                        disabled={true}
                                    />
                                    <Tooltip title="The above field will be updated to the one below">
                                        <IconButton
                                            size="large"
                                            color="secondary"
                                        >
                                            <ArrowRightAltIcon fontSize='large' />
                                        </IconButton>
                                    </Tooltip>
                                    <TextField
                                        fullWidth
                                        required
                                        margin="normal"
                                        label={field}
                                        name={field}
                                        type='text'
                                        value={newValue}
                                        disabled={true}
                                    />
                                </Container>
                            )
                        })
                    ) : null
                }

            </Grid>
        )
    }

    useEffect(() => {
        if (askAppControlPasswordKey && agree !== "" && permissionToken !== "") {
            setIsDisabledConfirm(false)
        } else if (askAppControlPasswordKey) {
            setIsDisabledConfirm(true)
        }

        if (newFieldsValue && oldFieldsValue) {
            const jsx = changedFields(oldFieldsValue, newFieldsValue)
            setChangedFieldJsx(jsx)
        } else {
            setDialogHeight("")
        }

    }, [newFieldsValue, oldFieldsValue, progressStatus, askAppControlPasswordKey])


    const handleTokenchange = (e) => {
        setPermissionToken(e.target.value)
        if (e.target.value === "" || !e.target.value) {
            setIsDisabledConfirm(true)
        } else if (agree === "" || !agree) {
            setIsDisabledConfirm(true)
        } else {
            setIsDisabledConfirm(false)
        }
    }

    const handleAgree = (e) => {
        setAgree(e.target.value)
        if (e.target.value === "" || !e.target.value) {
            setIsDisabledConfirm(true)
        } else if (permissionToken === "" || !permissionToken) {
            setIsDisabledConfirm(true)
        } else {
            setIsDisabledConfirm(false)
        }
    }

    const handleBtnValidation = () => {
        if (askAppControlPasswordKey) {
            if (askAppControlPasswordKey && (permissionToken === "" || !permissionToken) || (agree === "" || !agree)) {
                setSnackVarient("error")
                setSnackMessage("Please Fill The Required Fileds.")
                setSnackOpen(true)
                setIsDisabledConfirm(true)
            }
        }
    }

    const handleConfirmBtn = () => {
        if (askAppControlPasswordKey) {
            if (permissionToken !== "evg5v9zHQyA0vWDqIHaFLCLYz847dkCH") {
                setIsDisabledConfirm(true)
                setSnackMessage("Access Denied: Your token has either expired or is incorrect. Please verify your credentials and try again.")
                setSnackVarient("error")
                setSnackOpen(true)
            } else {
                setIsDisabledConfirm(false)
                handleConfirm()
            }

            if (askAppControlPasswordKey && (permissionToken === "" || !permissionToken)) {
                setSnackVarient("error")
                setSnackMessage("Please Fill The Required Fileds.")
                setSnackOpen(true)
                setIsDisabledConfirm(true)
            }
        } else {
            handleConfirm()
        }
    }

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason === "backdropClick" && (progressStatus || loadingProgress)) {

                } else {
                    handleClose()
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={newDialogSize}
            fullWidth
        >
            <DialogContent sx={{ minHeight: dialogHeight }} className='pt-10 pb-32 py-20 flex flex-col justify-center items-center h-full w-full '>

                {!progressStatus ? (
                    <>
                        <div className="flex justify-end w-full">
                            <IconButton
                                size="large"
                                onClick={() => handleClose()}
                            >
                                <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
                            </IconButton>
                        </div>
                        <DialogTitle id="alert-dialog-title" sx={{ paddingTop: 0 }}>{"Confirm Changes"}</DialogTitle>

                        <DialogContent className='h-full w-full'>
                            <DialogContentText id="alert-dialog-description" className='text-center'>
                                {description}
                            </DialogContentText>

                            {
                                changedFieldJsx && (
                                    changedFieldJsx
                                )
                            }

                            {
                                loaderProgress && loadingProgress && (
                                    <Box >
                                        <LinearProgress color='secondary' sx={{ height: "0.5rem", marginTop: "1rem" }} />
                                    </Box>
                                )
                            }

                            {
                                askAppControlPasswordKey && !progressStatus && (
                                    <Grid container spacing={2} alignItems="center" className='flex justify-center items-center py-20'>
                                        <Grid item xs={12} sm={5}>
                                            <TextField
                                                label={"Permission Token"}
                                                value={permissionToken}
                                                onChange={(e) => handleTokenchange(e)}
                                                fullWidth
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                label={"Changes Reviwed"}
                                                value={agree}
                                                onChange={(e) => handleAgree(e)}
                                                fullWidth
                                                required
                                                select
                                            >
                                                <MenuItem value="Agree">
                                                    Agree
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                )
                            }


                        </DialogContent>
                        <DialogActions className='gap-10'>
                            <Button onClick={handleClose} color="secondary">
                                Cancel
                            </Button>
                            <div onClick={handleBtnValidation}>
                                <Button onClick={(() => handleConfirmBtn())} color="primary" variant="contained" disabled={isDisabledConfirm}>
                                    {btnName}
                                </Button>
                            </div>
                        </DialogActions>
                    </>
                ) : (
                    <Container className='py-20 flex flex-col justify-center items-center gap-28 min-h-400 '>
                        <div className="relative flex justify-center items-center">
                            <CircularProgress size={100} />
                            <div className="absolute flex justify-center items-center">
                                <div className="transform transition-transform duration-500 ease-in-out animate-pulse">
                                    {progressStatus?.icon}
                                </div>
                            </div>
                        </div>
                        <div className="relative transform transition-transform duration-500 ease-in-out animate-pulse">
                            {progressStatus?.title}
                        </div>

                    </Container>
                )
                }


            </DialogContent>

            <SnackBarComponent
                setSnackOpen={setSnackOpen}
                snackOpen={snackOpen}
                snackVarient={snackVarient}
                snackMessage={snackMessage}
            />
        </Dialog >
    );
};

export default ConfirmDialog;