import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect } from "react";
import { DialogContent, DialogContentText, IconButton } from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

export default function SharedDiag({ status, OnhandleRemove, message }) {
  const [open, setOpen] = React.useState(status);

  const handleClose = () => {
    OnhandleRemove(false);
    setOpen(false);
  };
  useEffect(() => {
    setOpen(status);
  }, [status]);

  const handleAgree = () => {
    OnhandleRemove(true);
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogContent className="pt-10 pb-32 py-20 flex flex-col justify-center items-center">
        <div className="flex justify-end w-full">
          <IconButton size="large" onClick={handleClose}>
            <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
          </IconButton>
        </div>
        <DialogTitle id="alert-dialog-title" sx={{ paddingTop: 0 }}>
          {"Confirm"}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="gap-12">
          <Button onClick={handleClose} className="px-24" color="secondary">
            Disagree
          </Button>
          <Button
            onClick={handleAgree}
            color="primary"
            variant="contained"
            className="px-24"
            // disabled={isDisabledConfirm}
          >
            Agree
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>

    // <div>
    //   <Dialog
    //     open={open}
    //     onClose={handleClose}
    //     aria-labelledby="alert-dialog-title"
    //     aria-describedby="alert-dialog-description"
    //   >
    //     <DialogTitle id="alert-dialog-title" className="text-15">{message}</DialogTitle>
    //     <DialogActions>
    //       <Button
    //         className="border-2 border-solid px-24 py-20"
    //         onClick={handleClose}
    //       >
    //         Disagree
    //       </Button>
    //       <Button
    //         className="border-2 border-solid px-24 py-20"
    //         onClick={handleAgree}
    //         autoFocus
    //       >
    //         Agree
    //       </Button>
    //     </DialogActions>
    //   </Dialog>
    // </div>
  );
}
