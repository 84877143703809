import Button from "@mui/material/Button";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import FuseLoading from "@fuse/core/FuseLoading";
import _ from "@lodash";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Box from "@mui/system/Box";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import DateTimePicker from "@mui/lab/DateTimePicker";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import {
  addContact,
  getContact,
  newContact,
  removeContact,
  selectContact,
  updateContact,
} from "../store/providerSlice";
import ContactEmailSelector from "./email-selector/ContactEmailSelector";
import { getContacts } from "../store/providersSlice";
import SharedDiag from "app/shared-components/SharedDiag";
import { useState } from "react";
import { showMessage } from "app/store/fuse/messageSlice";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  name: yup.string().required("You must enter a Name of Provider"),
  firstname: yup.string().required("You must enter a First Name of Provider"),
  lastname: yup.string().required("You must enter a Last Name of Provider"),
  username: yup.string().required("You must enter a User Name of Provider"),
  email: yup.string().required("You must enter a User Email of Provider"),
  password: yup.string().required("You must enter a User Password of Provider"),
});

/**
 * Default values for provider and will be update once the API will be updated
 */
const ProvidersForm = (props) => {
  const contact = useSelector(selectContact);
  const routeParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState,
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const form = watch();
  const [DialogStatus, setDialogStatus] = useState(false);

  useEffect(() => {
    if (routeParams.id === "new") {
      dispatch(newContact());
    } else {
      dispatch(getContact(routeParams.id)).then(() => {
        contact.forEach((provider) => {
          setValue("name", provider.name);
          setValue("firstname", provider.user.firstname);
          setValue("lastname", provider.user.lastname);
          setValue("username", provider.user.username);
          setValue("email", provider.user.email);
        });
      });
    }
  }, [dispatch, routeParams, setValue, trigger]);

  useEffect(() => {
    reset({ ...contact });
  }, [contact, reset]);

  /**
   * Form Submit
   */

  function onSubmit(data) {
    if (routeParams.id === "new") {
      dispatch(addContact(data)).then((res) => {
        if (res?.payload?.success) {
          navigate(`/apps/providers`);
          dispatch(getContacts());     
        } else {
          dispatch(
            showMessage({
              message: res?.payload?.error?.message,
              variant: "error",
            })
          );
        }
      });
    } else {
      let providerData = {};
      providerData["provider_id"] = routeParams.id;
      providerData["user_id"] = data[0].user_id;
      providerData["name"] = data.name;
      providerData["firstname"] = data.firstname;
      providerData["lastname"] = data.lastname;
      providerData["username"] = data.username;
      providerData["password"] = data.password;
      providerData["role"] = 2;
      providerData["isDeleted"] = 0;

      dispatch(updateContact(providerData)).then(() => {
        navigate(`/apps/providers/${routeParams.id}`);
        dispatch(getContacts());
      });
    }
  }

  function handleRemoveContact(StatusAgree) {
    if (StatusAgree) {
      let id = {
        id: routeParams.id,
      };
      dispatch(removeContact(id)).then((dat) => {
        if (!dat?.payload?.success) {
          navigate(`/apps/providers`);
          dispatch(
            showMessage({ message: dat?.payload?.message, variant: "error" })
          );
          dispatch(getContacts());
        } else {
          navigate(`/apps/providers`);
          dispatch(
            showMessage({
              message: "Provider has been deleted successfully!",
              variant: "success",
            })
          );
          dispatch(getContacts());
        }
      });
    } else {
      navigate(`/apps/providers`);
      dispatch(getContacts());
    }
  }

  if (_.isEmpty(form) || !contact) {
    return <FuseLoading />;
  }

  return (
    <>
      {DialogStatus && (
        <SharedDiag
          status={DialogStatus}
          OnhandleRemove={handleRemoveContact}
          message={"Are you sure you want to delete the provider?"}
        />
      )}
      <Box
        className="relative w-full h-80 sm:h-80 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.default",
        }}
      ></Box>
      <div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="Provider Name for Agency Reference"
              placeholder="Provider Name"
              type="text"
              id="name"
              error={!!errors.name}
              helperText={errors?.name?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>heroicons-solid:key</FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Divider textAlign="left" className="w-full mt-32">
          <Chip label="Provider Information for Admin Account Creation" />
        </Divider>

        <Controller
          control={control}
          name="firstname"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="Provider First Name"
              placeholder="Provider contact person first name"
              id="firstname"
              error={!!errors.firstname}
              helperText={errors?.firstname?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="lastname"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="Provider Last Name"
              placeholder="Provider contact person last name"
              id="lastname"
              error={!!errors.lastname}
              helperText={errors?.lastname?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="username"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="Provider Username"
              placeholder="Provider contact person username"
              id="username"
              error={!!errors.username}
              helperText={errors?.username?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32"
              label="Email"
              placeholder="Email"
              variant="outlined"
              disabled={routeParams.id !== "new" ? true : false}
              fullWidth
              error={!!errors.email}
              helperText={errors?.email?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>heroicons-solid:mail</FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-32"
              label="Password"
              type="password"
              placeholder="Password"
              variant="outlined"
              fullWidth
              error={!!errors.password}
              helperText={errors?.password?.message}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:lock-closed
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
      <Box
        className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t"
        sx={{ backgroundColor: "background.default" }}
      >
        {routeParams.id !== "new" && (
          <Button
            color="error"
            onClick={() => {
              setDialogStatus(true);
            }}
          >
            Delete
          </Button>
        )}
        <Button className="ml-auto" component={NavLinkAdapter} to={-1}>
          Cancel
        </Button>
        {routeParams.id === "new" ? (
          <Button
            className="ml-8"
            variant="contained"
            color="secondary"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        ) : (
          <Button
            className="ml-8"
            variant="contained"
            color="secondary"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>
        )}
      </Box>
    </>
  );
};

export default ProvidersForm;
