import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const IpApp = lazy(() => import('./IpApp'));

const IpAppConfig = {
    settings: {
        layout: {},
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/ip/configurations',
            element: <IpApp />,
        }
    ],
};

export default IpAppConfig;
