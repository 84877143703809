const locale = {
  APPLICATIONS: 'Monitoring',
  DASHBOARD: 'Dashboard',
  CALENDAR: 'Calendar',
  ECOMMERCE: 'E-Commerce',
  ACADEMY: 'Academy',
  MAIL: 'Mail',
  TASKS: 'Tasks',
  FILE_MANAGER: 'File Manager',
  CONTACTS: 'Contacts',
  CHAT: 'Chat',
  SCRUMBOARD: 'Scrumboard',
  NOTES: 'Notes',
  ApkManager: 'Apk Management',
  MASTERCONTROLS: 'MASTER CONTROLS',
  FILEMANAGER: 'File Manager',
  APKMANAGER: 'Apk Manager',
  PRODUCTIONBUILD: "Production Build",
  APKKEYS: 'APK Keys',
  APKKEYSMANAGEMENT: 'Apk Keys Management',
  ADSTATUS: 'Posted Ads',
  ADSPLUS: 'Ads Plus',
  GTFS: 'GTFS',
  STOPS: 'Stops',
  BAYS: 'Bays',
  AVAILABLEMSG: 'Available Messages',
  ApiIntegration: 'Api Integration',
  PLAYER: 'APK Player Manager',
  DAASTEST: 'Daas Test',
  ALERTTRIGGER: 'Messages',
  APKSETTING: 'Apk Settings',
  CRADLEPOINT: 'CRADLE POINT',
  IPCONFIGURATIONS: 'IP CONFIGURATIONS',
  CONFIGURATIONS: 'CONFIGURATIONS',
};

export default locale;
